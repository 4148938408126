import React from 'react';
import { LayoutSingleColumn, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import css from './ControlledLandingPage.module.css';
import SectionHeroCss from './sections/SectionHero/SectionHero.module.css';

const ControlledLandingPageComponent = props => {
  const { currentUser, scrollingDisabled, history } = props;

  return (
    <Page
      title={'Jaikan'}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: 'Jaikan',
        description: 'Jaikan',
      }}
    >
      <LayoutSingleColumn
        // mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer />}
        footer={<FooterContainer />}
      >
        <div className={css.wrapper}>
          <div className={SectionHeroCss.wrapper} />
          {/* <div className={css.sectionWrapper}></div> */}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ControlledLandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(ControlledLandingPageComponent);

export default ControlledLandingPage;
